'use strict'

###*
 # @ngdoc object
 # @name mundoUtils
 # @description

###
angular
  .module 'mundoUtils', [
    'ui.router'
  ]
